/* eslint-disable react/prop-types */
import React from 'react';
import { FaQuestion } from 'react-icons/fa';
import { Header } from './styles';

export default function Header3({ title }) {
  const [isOpen, setIsOpen] = React.useState(true);
  let description = '';
  let information = '';

  switch (title) {
    case '/NewEnterprise': {
      description = 'Criar Nova Empresa';
      information = 'Criar uma nova Empresa';
      break;
    }
    case '/cash': {
      description = 'Caixa';
      information = 'Informe o Dinheiro que possue no seu caixa para gerar seu histórico'
      + '. Toda vez que o valor é informado, o caixa é fechado';
      break;
    }
    case '/charts': {
      description = 'Gráficos';
      information = 'Acompanhe o Faturamento de sua Empresa';
      break;
    }
    case '/Conditions': {
      description = 'Condições de Uso';
      information = '';
      break;
    }
    case '/config': {
      description = 'Empresa';
      information = '';
      break;
    }
    case '/deposit': {
      description = 'Depósito';
      information = 'Realize um Depósito para não perder acesso à aplicação';
      break;
    }
    case '/downloads': {
      description = 'Downloads';
      information = 'Realize o Downloads das Tabelas de Dados de sua Empresa';
      break;
    }
    case '/editpayment': {
      description = 'Editar Pagamento';
      information = 'Edite um Pagamento';
      break;
    }
    case '/editproduct': {
      description = 'Editar Produto';
      information = 'Edite seus Produtos. A edição do produto não implica na mudança das vendas passadas.';
      break;
    }
    case '/editsale': {
      description = 'Editar Venda';
      information = 'Edite uma Venda que já foi realizada. Desative-a para cancelamento.'
      + 'Editar o produto de uma venda não irá alterar os dados originais do produto';
      break;
    }
    case '/encalhe': {
      description = 'Encalhe';
      information = '';
      break;
    }
    case '/entrada': {
      description = 'Entrada';
      information = 'Veja todos os Lançamentos Registrados Como Entrada. Elas podem ser utilizadas pelos usuários Vendedores para Adicionar itens no estoque.';
      break;
    }
    case '/inventario': {
      description = 'Inventário';
      information = 'Veja todos os Lançamentos registrados como Inventário. Eles debitam deseu estoque, porém com um preço de Venda R$0';
      break;
    }
    case '/newcategory': {
      description = 'Nova Categoria';
      information = 'Crie uma nova Categoria e organize suas vendas';
      break;
    }
    case '/newproduct': {
      description = 'Novo Produto';
      information = 'Crie um novo Produto, o Código pode ser lido atavés da câmera de seu dispositivo. Ative a Caixa Estoque para administrar as quantidades entradas e saídas';
      break;
    }
    case '/newsale': {
      description = 'Nova Venda';
      information = 'Adicione os produtos para que seja realizada a Venda';
      break;
    }
    case '/payments': {
      description = 'Pagamentos';
      information = 'Informe Pagamentos realizados em dinheiro. Eles serão debiados de seu caixa.';
      break;
    }
    case '/products': {
      description = 'Produtos';
      information = 'Veja todos os produtos cadastrados em sua Empresa. Clique para editar';
      break;
    }
    case '/sales': {
      description = 'Vendas';
      information = 'Veja todas suas vendas por dia. Clique para editar';
      break;
    }
    case '/register': {
      description = 'Criar Vendedor';
      information = 'Crie um usuário Vendedor. Ele possúi acesso Limitado aos Custos e Gráficos';
      break;
    }
    case '/stock': {
      description = 'Estoque';
      information = 'Veja o Valor em Estoque por Categoria de Produto.';
      break;
    }
    case '/users': {
      description = 'Usuários';
      information = 'Lista de usuários cadastrados em sua Empresa.';
      break;
    }
    case '/profile': {
      description = 'Perfil';
      information = 'Edite suas Informações Pessoais. Alguns dispositivos possuem mais de uma câmera. Seleciona a mais indicada';
      break;
    }
    case '/upload': {
      description = 'Upload';
      information = 'Faça Upload de Imagens';
      break;
    }
    default:
  }

  return (
    <Header>
      <strong>{description}</strong>
      { !information ? null
        : (
          <FaQuestion
            onClick={() => setIsOpen(!isOpen)}
            size="10px"
            style={{
              color: 'white',
              cursor: 'pointer',
            }}
          />

        )}
      { isOpen ? null
        : (
          <span>{information}</span>
        )}
    </Header>
  );
}
