import styled from 'styled-components';

export const Container = styled.div`
display: ${(props) => (props.position === 'below' ? 'block' : null)};
position: ${(props) => (props.position === 'below' ? 'fixed' : null)};
width: ${(props) => (props.position === 'below' ? '100%' : null)};
bottom: ${(props) => (props.position === 'below' ? '0vh' : null)};
left: ${(props) => (props.position === 'below' ? '0' : null)};
background: ${(props) => (props.position === 'below' ? '#7159c1' : 'white')};
text-align: ${(props) => (props.position === 'below' ? 'center' : null)};

margin: 0 auto;

`;
export const Content = styled.div`
height: 50px;
max-width: 800px;
margin: 0 auto;
background: ${(props) => (props.position === 'below' ? '#7159c1' : null)};
display: flex;
justify-content: space-between;
align-items: center;
img {
    height: 40px;
    min-width: 40px;
}
nav {
  display: grid;
  grid-gap:6px;
  grid-template-columns: repeat(3,1fr);
  justify-content: space-around;
  align-items: center;
  width:100%;
  align-self:center;
  margin: 0 10px 0 10px;
  img {
    height: 50px;
    width: 50px;
  }
  a {
    background: ${(props) => (props.position === 'below' ? '#7159f1' : '#fff')};
    justify-content: center;
    border:2px;
    display:flex;
    height:40px;
    align-self:center;
    text-align:center;
    align-items: center;
    border-radius:6px;
    font-weight: bold;
    color: ${(props) => (props.position === 'below' ? '#fff' : '#7159c1')};

  }

  @media (max-width: 100%) {
    a { font-size: 10px; }
  }
  @media (max-width: 400px) {
    a { font-size: 10px; }
  }
}

aside {
  display: flex;
  align-items: center;
}

`;

export const Content2 = styled.div`
height: 50px;
max-width: 800px;
margin: 0 auto;
background: ${(props) => (props.position === 'below' ? '#7159c1' : null)};
display: flex;
justify-content: space-between;
align-items: center;
img {
    height: 40px;
    min-width: 40px;
}
nav {
  display: grid;
  grid-gap:6px;
  grid-template-columns: repeat(4,1fr);
  grid-template-columns: ${(props) => (props.charts === 0 ? 'repeat(4,1fr)' : 'repeat(3,1fr)')};
  justify-content: space-around;
  align-items: center;
  width:100%;
  align-self:center;
  margin: 0 10px 0 10px;
  img {
    height: 50px;
    width: 50px;
  }
  a {
    background: ${(props) => (props.position === 'below' ? '#7159f1' : '#fff')};
    justify-content: center;
    border:2px;
    display:flex;
    height:40px;
    align-self:center;
    text-align:center;
    align-items: center;
    border-radius:6px;
    font-weight: bold;
    color: ${(props) => (props.position === 'below' ? '#fff' : '#7159c1')};

  }

  @media (max-width: 100%) {
    a { font-size: 10px; }
  }
  @media (max-width: 400px) {
    a { font-size: 10px; }
  }
}

aside {
  display: flex;
  align-items: center;
}

`;

export const Profile = styled.div`
display: flex;
margin-left: 0px;
padding-left: 0px;
border-left: 1px solid #eee;
justify-content:space-between;
div {
  text-align: right;
  margin-right: 10px;

  strong {
    display: block;
    color: #333
  }

  a {
    display: block;
    margin-top: 2px;
    font-size: 14px;
    color: #999;
  }
  @media (max-width: 400px) {
    strong {
      font-size: 8px;
    }
    a {
      font-size: 8px;
  }}
}
img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
`;
