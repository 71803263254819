import styled from 'styled-components';

export const Header = styled.div`
max-width: 800px;
margin: auto;
border-radius:3px;
display:grid;
grid-template-columns: 1fr 15px;
background: linear-gradient(-5deg, #7159c1, #333);
align-items: center;
strong {
  text-align: center;
  color: #fff;
  font-size: 20px;
}
`;
