import styled from 'styled-components';

export const Container = styled.div`
display: ${(props) => (props.position === 'below' ? 'block' : null)};
position: ${(props) => (props.position === 'below' ? 'fixed' : null)};
width: ${(props) => (props.position === 'below' ? '100%' : null)};
bottom: ${(props) => (props.position === 'below' ? '0vh' : null)};
left: ${(props) => (props.position === 'below' ? '0' : null)};
background: ${(props) => (props.position === 'below' ? '#7159c1' : '#7159c1')};
text-align: ${(props) => (props.position === 'below' ? 'center' : null)};

margin: 0 auto;

`;
export const Content = styled.div`
height: 50px;
max-width: 800px;
margin: 0 auto;
background: ${(props) => (props.position === 'below' ? '#7159c1' : '#7159c1')};
display: flex;
justify-content: space-between;
align-items: center;
img {
    height: 40px;
    min-width: 40px;
}
nav {
  display: grid;
  grid-gap:6px;
  grid-template-columns: repeat(1,1fr);
  justify-content: space-around;
  align-items: center;
  width:100%;
  align-self:center;
  margin: 0 10px 0 10px;
  img {
    height: 50px;
    width: 50px;
  }
  a {
    background: ${(props) => (props.position === 'below' ? '#7159f1' : '#7159f1')};
    justify-content: center;
    border:3px;
    display:flex;
    height:40px;
    align-self:center;
    text-align:center;
    align-items: center;
    border-radius:6px;
    font-weight: bold;
    color: ${(props) => (props.position === 'below' ? '#fff' : '#fff')};

  }

  @media (max-width: 100%) {
    a { font-size: 20px; }
  }
  @media (max-width: 400px) {
    a { font-size: 2  0px; }
  }
}

aside {
  display: flex;
  align-items: center;
}

`;
